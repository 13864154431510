// import * as React from 'react'
// import Alert from '/src/components/Basic/Alert'

import { filterTypeAndBookAppointment } from '/src/functions/forms/bookAppointment'

export const sendToOfficeLine = async (
    officeLineFormattedDateTime,
    appointmentData,
    setIsScheduling,
    setIsScheduled,
    setScheduleErr
) => {
    let submissionType
    let leadId

    if (appointmentData.plid) {
        submissionType = 'plid'
        leadId = appointmentData.plid
    } else if (appointmentData.lid) {
        submissionType = 'lid'
        leadId = appointmentData.lid
    }

    const olData = new URLSearchParams({
        ...(submissionType === 'plid' ? { customerReferralCode: leadId } : undefined),
        ...(submissionType === 'lid' ? { leadId: leadId } : undefined),
        collectionId: appointmentData.type,
        startTime: officeLineFormattedDateTime,
        durationMinutes: appointmentData.duration
    })

    await filterTypeAndBookAppointment(
        submissionType,
        olData,
        officeLineFormattedDateTime,
        setIsScheduling,
        setIsScheduled,
        setScheduleErr
    )
}

export const runSubmitting = () => {
    // Set the 'submitting' state to the 'book' button
    const bookButton = document.querySelector('.sc-ifAKCX')
    bookButton.classList.remove('sc-ifAKCX', 'hqlASh')
    bookButton.classList.add('btn', 'btn-primary', 'btn-submitting')
    bookButton.disabled = true
}

export const submittedState = (type, leadId) => {
    if (leadId.plid || leadId.lid) {
        const successButtonObserver = new MutationObserver((mutations, observer) => {
            const successButtonExists = document.body.contains(document.getElementsByClassName('sc-EHOje')[0])

            if (successButtonExists) {
                const successButton = document.querySelector('.sc-EHOje')

                if (type === 'form') {
                    successButton.classList.add('c-appointment-picker__book-button--form')

                    const alert = `
                        <div class="alert alert-warning c-alert c-alert--lg" role="alert">
                            <p>You must proceed with filling in the rest of the form before this time is officially booked in for you.</p>
                        </div>
                    `
                    successButton.insertAdjacentHTML('afterend', alert)
                } else if (type === 'standard') {
                    successButton.classList.add('c-appointment-picker__book-button--standard')

                    const alert = `
                        <div class="alert alert-success c-alert c-alert--lg" role="alert">
                            <p>Your chosen timeslot has been confirmed for you, and a representative will call you at the assigned time.</p>
                        </div>
                    `
                    successButton.insertAdjacentHTML('afterend', alert)
                }

                observer.disconnect()
                return
            }
        })

        successButtonObserver.observe(document, {
            childList: true,
            subtree: true
        })
    } else {
        // Show an error on the page for the user
        const container = document.querySelector('.sc-bwzfXH')
        setTimeout(() => {
            container.innerHTML =
                '<div class="alert alert-danger c-alert" role="alert"><p><strong>There was a problem booking your appointment - you do not have a customer reference ID.</strong></p><p>Please <a href="/contact-us/existing-customers">contact us</a> and we can get this sorted for you.</p></div>'
        }, 100)
    }
}
