import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { formatOfficeLineDateTime } from '/src/functions/timeFormatter'
import { initNextPrev, fetchDatesAndTimes, timeSlotValidator } from './calendarInit'
import { sendToOfficeLine, runSubmitting, submittedState } from './calendarSubmit'
import useTimeout from '/src/hooks/useTimeout'
import DayTimePicker from '@mooncake-dev/react-day-time-picker'
import './styles.scss'

const AppointmentPicker = ({ weeks, type, leadId, collectionId, appointmentDuration, setSlotFieldValue }) => {
    const [isScheduling, setIsScheduling] = useState(false)
    const [isScheduled, setIsScheduled] = useState(false)
    const [scheduleErr, setScheduleErr] = useState('')

    const appointmentData = useMemo(() => {
        return {
            plid: leadId.plid,
            lid: leadId.lid,
            type: collectionId,
            duration: appointmentDuration,
            slots: 15 // Slot every 15 minutes
        }
    }, [leadId, collectionId, appointmentDuration])

    useEffect(() => {
        fetchDatesAndTimes(weeks, type, appointmentData)
    }, [weeks, type, appointmentData])

    useTimeout(() => {
        // Stop the calendar next/prev buttons from trying to submit the form (if sitting within a larger form)
        initNextPrev()
    }, 100)

    const handleScheduled = (dateTime) => {
        setIsScheduled(true)

        // Reformat the date for Office Line
        const officeLineFormattedDateTime = formatOfficeLineDateTime(dateTime)

        if (type === 'form') {
            // If the appointment picker is part of a larger form, apply the calendar value to a field
            setSlotFieldValue(officeLineFormattedDateTime)
        } else if (type === 'standard') {
            // If is standard appointment picker, send data to office line upon success
            sendToOfficeLine(
                officeLineFormattedDateTime,
                appointmentData,
                setIsScheduling,
                setIsScheduled,
                setScheduleErr
            )
        }
    }

    if (isScheduling) {
        setIsScheduling(true)

        runSubmitting()
    }

    if (isScheduled) {
        submittedState(type, leadId)
    }

    return (
        <DayTimePicker
            timeSlotSizeMinutes={appointmentData.slots}
            timeSlotValidator={timeSlotValidator}
            onConfirm={handleScheduled}
            isLoading={isScheduling}
            isDone={isScheduled}
            err={scheduleErr}
        />
    )
}

AppointmentPicker.propTypes = {
    /**
     * Number of weeks of dates to show on the calendar
     */
    weeks: PropTypes.number,
    /**
     * Type of appointment picker
     */
    type: PropTypes.oneOf(['standard', 'form']),
    /**
     * Lead ID from URL parameter
     */
    leadId: PropTypes.shape({
        plid: PropTypes.string,
        lid: PropTypes.string
    }),
    /**
     * Appointment type (numerical value)
     */
    collectionId: PropTypes.number,
    /**
     * Appointment duration (numerical value)
     */
    appointmentDuration: PropTypes.number
}

AppointmentPicker.defaultProps = {
    weeks: 2,
    type: 'standard',
    leadId: {
        plid: 'TTEL8BF3SZ',
        lid: '109365'
    },
    collectionId: 2073,
    appointmentDuration: 60
}

export default AppointmentPicker
