import moment from 'moment'
import { generateMonth } from '/src/functions/timeFormatter'

let formattedDatesList = []

const handleButtonState = (type) => {
    const timeslotObserver = new MutationObserver(function mutationCallback() {
        const bookButtonExists = document.body.contains(document.getElementsByClassName('sc-ifAKCX')[0])

        if (bookButtonExists) {
            const bookButton = document.querySelector('.sc-ifAKCX')

            if (type === 'form') {
                bookButton.classList.add('c-appointment-picker__book-button--form')
            } else {
                bookButton.classList.add('c-appointment-picker__book-button--standard')
            }
        }
    })

    // Observe when the timeslot picker has been opened or closed
    timeslotObserver.observe(document.querySelector('.sc-bdVaJa'), {
        childList: true
    })
}

const runEnableDateObserver = (date) => {
    const monthChangeObserver = new MutationObserver(function mutationCallback() {
        setEnabledDates(date)
    })

    const timeslotObserver = new MutationObserver(function mutationCallback() {
        setEnabledDates(date)
    })

    // Check if the calendar is on the DOM before running mutation observers
    const calendarExists = document.body.contains(document.getElementsByClassName('sc-dxgOiQ')[0])

    if (calendarExists) {
        // Observe when month has been changed
        monthChangeObserver.observe(document.querySelector('.sc-dxgOiQ'), {
            attributes: true
        })

        // Observe when the timeslot picker has been opened or closed
        timeslotObserver.observe(document.querySelector('.sc-bdVaJa'), {
            childList: true
        })
    }
}

const runDisableDateObserver = () => {
    const monthChangeObserver = new MutationObserver(function mutationCallback() {
        disableAllDates()
    })

    const timeslotObserver = new MutationObserver(function mutationCallback() {
        disableAllDates()
    })

    // Observe when month has been changed
    monthChangeObserver.observe(document.querySelector('.sc-dxgOiQ'), {
        attributes: true
    })

    // Observe when the timeslot picker has been opened or closed
    timeslotObserver.observe(document.querySelector('.sc-bdVaJa'), {
        childList: true
    })
}

const disableAllDates = () => {
    // Get all the numbers in the month
    const domDates = document.querySelectorAll('.sc-jzJRlG')

    // Remove the 'active' class and add the 'inactive' class for every date
    domDates.forEach((node) => {
        node.classList.remove('cTgcFX')
        node.classList.add('fOFptH')
    })
}

const handleDisabledDates = () => {
    disableAllDates()

    runDisableDateObserver()
}

const enableDates = (usableDate, dateMonth, calendarMonth) => {
    // Get all the numbers in the month
    const domDates = document.querySelectorAll('.sc-jzJRlG')

    // If the month and date matches what is in Office Line, enable the dates
    domDates.forEach((node) => {
        if (usableDate.getDate().toString() === node.innerHTML && dateMonth === calendarMonth) {
            node.classList.remove('fOFptH')
            node.classList.add('cTgcFX')
        }
    })
}

const setEnabledDates = (date) => {
    const formatDate = `20${date}`
    const usableDate = new Date(formatDate)
    const dateMonth = generateMonth(usableDate)

    // Get the month name of this class (check it exists first)
    const calendarMonthExists = document.body.contains(document.getElementsByClassName('sc-kGXeez')[0])

    if (calendarMonthExists) {
        const calendarMonthElement = document.querySelector('.sc-kGXeez')
        const calendarMonth = calendarMonthElement.innerHTML.slice(0, calendarMonthElement.innerHTML.length - 5)

        enableDates(usableDate, dateMonth, calendarMonth)
    }
}

const handleEnabledDates = (fetchData) => {
    let allDatesArray = []

    fetchData.forEach((dataCollection) => {
        const datesList = dataCollection.availableStartTimes
        const datesArray = Object.entries(datesList)

        allDatesArray.push(datesArray)
    })

    allDatesArray.forEach((array) => {
        array.forEach((node) => {
            const date = node[0]
            const times = node[1]

            if (times.length > 0) {
                setEnabledDates(date)

                runEnableDateObserver(date)

                times.forEach((node) => {
                    const formatDate = `20${date} ${node}`
                    const usableDate = new Date(formatDate.replace(/-/g, '/')) // Replace is fix for Safari date format

                    formattedDatesList.push(usableDate)
                })
            }
        })
    })
}

export const initNextPrev = () => {
    const nextPrevButtons = document.querySelectorAll('.sc-kAzzGY')

    nextPrevButtons.forEach((node) => {
        node.addEventListener('click', function (event) {
            event.preventDefault()
        })
    })
}

export const timeSlotValidator = (slotTime) => {
    const morningTime = new Date(slotTime.getFullYear(), slotTime.getMonth(), slotTime.getDate(), 9, 15, 0)

    const eveningTime = new Date(slotTime.getFullYear(), slotTime.getMonth(), slotTime.getDate(), 19, 0, 0)

    if (slotTime.getTime() > morningTime.getTime() && slotTime.getTime() < eveningTime.getTime()) {
        let isValid = false

        for (let index = 0; index < formattedDatesList.length && !isValid; index++) {
            const availableDate = formattedDatesList[index]

            if (availableDate.toISOString() === slotTime.toISOString()) {
                isValid = true
            }
        }

        return isValid
    } else {
        return false
    }
}

const detectAppointments = (fetchData, type) => {
    let hasAppointments = false
    const calendarContainer = document.querySelector('.sc-dxgOiQ')

    fetchData.forEach((dataCollection) => {
        const week = Object.values(dataCollection.availableStartTimes)

        week.forEach((day) => {
            if (day.length > 0) {
                hasAppointments = true
            }
        })
    })

    if (!hasAppointments) {
        // If no appointments available
        setTimeout(() => {
            calendarContainer.innerHTML =
                '<div class="alert alert-danger c-alert"><p>We are very sorry, there are no appointments available at this time.</p></div>'

            // Stop the calendar next/prev buttons from trying to submit the form
            const nextPrevButtons = document.querySelectorAll('.sc-kAzzGY')

            nextPrevButtons.forEach((node) => {
                node.disabled = true
                node.classList.add('is-disabled')
            })
        }, 100)
    } else {
        // If appointments are available
        handleDisabledDates()

        handleEnabledDates(fetchData)

        handleButtonState(type)
    }
}

export const fetchDatesAndTimes = (weeks, type, appointmentData) => {
    let increment = 7
    let weekCollection = [
        moment().format('YY-MM-DD') // Today's date
    ]

    for (let i = 1; i < weeks; i++) {
        weekCollection[i] = moment().add(increment, 'days').format('YY-MM-DD')

        increment += 7
    }

    const fetchCollection = []
    const fetchSuccess = (res) => (res.ok ? res.json() : Promise.resolve({}))
    weekCollection.forEach((week) => {
        const appointmentFetch = fetch(
            `https://www.protectline.co.uk/web/appointment/availability?collectionId=${appointmentData.type}&durationMinutes=${appointmentData.duration}&fromDate=${week}`
        ).then(fetchSuccess)

        fetchCollection.push(appointmentFetch)
    })

    Promise.all(fetchCollection)
        .then((fetchData) => {
            detectAppointments(fetchData, type)
        })
        .catch((error) => {
            console.log(error)
        })
}
