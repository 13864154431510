import { formatGtmAppointmentDateTime } from '/src/functions/timeFormatter'
import { pushToGtmDataLayer } from '/src/functions/gtm'

const bookAppointment = async (endPoint, olData, appointmentTime, setIsScheduling, setIsScheduled, setScheduleErr) => {
    try {
        const response = await fetch(endPoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: olData
        })

        setIsScheduling(false)

        if (response.ok) {
            setIsScheduled(true)

            // Push appointment time to GTM Data Layer
            await pushToGtmDataLayer([
                ['appointment_date_time', formatGtmAppointmentDateTime(appointmentTime)],
                ['event', 'appointment_booked']
            ])
        } else {
            setIsScheduled(false)
            setScheduleErr('There was an error with the calendar')

            // Show an error on the page for the user
            const container = document.querySelector('.sc-bwzfXH')
            setTimeout(() => {
                container.innerHTML = `
                    <div class="alert alert-danger c-alert" role="alert">
                        <p>
                            <strong>There was a problem booking your appointment - your customer reference ID is not valid.</strong>
                        </p>
                        <p>Please <a href="/contact-us/existing-customers">contact us</a> and we can get this sorted for you.</p>
                    </div>
                `
            }, 100)
        }
    } catch (err) {
        console.error('Office Line book appointment error:', err)
    }
}

export const filterTypeAndBookAppointment = (
    submissionType,
    olData,
    appointmentTime,
    setIsScheduling,
    setIsScheduled,
    setScheduleErr
) => {
    if (submissionType === 'lid') {
        const endPoint = 'https://pline.uk/web/appointment/bookUnassignedByLead'
        bookAppointment(endPoint, olData, appointmentTime, setIsScheduling, setIsScheduled, setScheduleErr)
    } else if (submissionType === 'plid') {
        const endPoint = 'https://www.protectline.co.uk/web/appointment/bookUnassigned'
        bookAppointment(endPoint, olData, appointmentTime, setIsScheduling, setIsScheduled, setScheduleErr)
    }
}
